/* Fullscreen container for map and detail panel */
.map-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.map-container.blurred {
  filter: blur(8px); /* Apply blur when splash screen is active */
}

.light-mode {
  background-color: #f5f5f7; /* Light gray for Apple-like feel */
  color: #000;
}

.dark-mode {
  background-color: #1e1e1e; /* Dark background for dark mode */
  color: #fff;
}

/* Header containing banner and toggle */
/* Header containing banner, search bar, and toggle */
/* Header with glass effect for light mode */
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: rgba(255, 255, 255, 0.5); /* Light mode background */
  backdrop-filter: blur(20px); /* Glass effect with blur */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 1000; /* Ensure it stays above the map */
  border-radius: 0 0 0 0; /* Optional: Add rounded corners at the bottom */
}

/* Header for dark mode */
.dark-mode .header {
  background: rgba(55, 55, 55, 0.6); /* Dark mode background */
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.1); /* Lighter shadow for dark mode */
  border-bottom: 1px solid rgba(100, 100, 100, 0.3);
}

/* Banner in the upper left corner */
.banner img {
  width: 150px; /* Adjust based on your banner image size */
  height: auto;
  border-radius: 8px;
}

/* Dark mode toggle icon button */
.dark-mode-toggle {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  color: #007aff; /* Blue for light mode */
  margin-right: 25px;
  margin-left: 25px;
  transition: color 0.3s ease;
}

.dark-mode-toggle:hover {
  color: #005bb5; /* Darker blue on hover */
}

.dark-mode .dark-mode-toggle {
  color: #f5f5f5; /* White for dark mode */
}

.dark-mode .dark-mode-toggle:hover {
  color: #bbbbbb; /* Light gray on hover in dark mode */
}


.map {
  height: 100vh;  /* Ensure full height is used */
  width: 100%;    /* Ensure full width is used */
  position: relative;
  z-index: 1;
}

/* Details panel */
.details-panel {
  position: absolute;
  right: 30px;
  top: 150px; /* Increased from 75px to 150px to lower the panel */
  height: auto;
  width: 350px;
  background: rgba(255, 255, 255, 0.55);
  backdrop-filter: blur(20px); /* Blur for a glass effect */
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12); /* Soft shadow for depth */
  padding: 25px;
  z-index: 10;
  border-radius: 24px; /* Larger rounded corners */
  transition: transform 0.3s ease-in-out;
  transform: translateX(0%);
  color: #333; /* Darker text for better readability */
}

.dark-mode .details-panel {
  background: rgba(44, 44, 46, 0.55);
  color: #f0f0f0; /* Light text for dark mode */
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.25);
}

/* Title styling */
.details-panel h2 {
  font-size: 1.8em;
  font-weight: 600;
  color: inherit;
  margin-bottom: 20px;
  text-align: center; /* Centered for a balanced look */
}

/* Project list styling */
.details-panel ul {
  list-style: none;
  padding: 0;
  margin-bottom: 20px; /* Add some space below the list */
}

.details-panel li {
  font-size: 1.1em;
  padding: 10px 0;
  color: inherit;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(200, 200, 200, 0.3);
}

.dark-mode .details-panel li {
  border-bottom: 1px solid rgba(100, 100, 100, 0.3);
}

/* Close button styling */
.close-button {
  display: block;
  background-color: #007aff;
  color: #ffffff;
  border: none;
  border-radius: 12px;
  padding: 12px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1em;
  box-shadow: 0 4px 12px rgba(17, 17, 18, 0.3);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  margin-top: 10px;
}

.close-button:hover {
  background-color: #005bb5;
  /* box-shadow: 0 6px 16px rgba(0, 122, 255, 0.5); */
}

/* Modern styling for icons or additional markers (optional) */
.project-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-color: #3474b8;
  border-radius: 50%;
}

.dark-mode .close-button {
  background-color: #444;
}

.dark-mode .close-button:hover {
  background-color: #333;
}

/* Scrollable panel content for smaller screens */
.details-panel {
  overflow-y: auto;
  overflow-x: hidden;
}

/* Header containing banner and toggle */
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  z-index: 1000; /* Ensure it stays above the map */
}

/* Banner in the upper left corner */
.banner img {
  width: 170.75px; /* Adjust based on your banner image size */
  height: auto;
  border-radius: 0px;
  margin-left: 10px; /* Add margin to push banner away from the left edge */
}

/* Banner in the upper left corner */
.splash-banner img {
  width: 170.75px; /* Adjust based on your banner image size */
  height: auto;
  border-radius: 0px;
}

/* Splash screen styling */
.splash-screen {
  position: fixed;
  top: 0;
  left: 300;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.7); */
  background: url('../data/splashBG.png') center center/cover no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000; /* Highest to overlay everything */
  color: #ffffff;
}

.splash-content {
  position: fixed;
  top: 200;
  left: 300;
  width: 60%;
  height: 600px;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(20px);
  padding: 0px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #333333;
  
}

/* .splash-content h1 {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
} */

.splash-content h1 {
  font-size: clamp(.75em, 5vw, 2em);
  font-weight: bold;
  margin-bottom: 10px;
}

/* .splash-content p {
  font-size: 1.1em;
  line-height: 1.5;
  margin-bottom: 30px;
} */

.splash-content p {
  font-size: clamp(0.75em, 3vw, 1.1em);
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 15px;
}

/* Continue button styling */
.continue-button {
  background-color: #007aff;
  color: #ffffff;
  border: none;
  border-radius: 12px;
  padding: 15px 25px;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 600;
  box-shadow: 0 4px 12px rgba(0, 122, 255, 0.3);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.continue-button:hover {
  background-color: #005bb5;
  box-shadow: 0 6px 16px rgba(0, 122, 255, 0.5);
}

/* Search bar styling */
.search-bar {
  flex-grow: 1;
  margin-left: 20px;
  margin-right: 20px;
  /* width: 300px; */
}

.search-bar input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1em;
  outline: none;
  transition: border-color 0.3s ease;
}

.search-bar input:focus {
  border-color: #007aff;
}

@media (max-width: 768px) {
  .details-panel {
    width: 95%;
    height: auto;
    bottom: 0; /* Position at the bottom */
    top: auto;
    right: 50%;
    transform: translateX(50%);
    border-radius: 16px 16px 0 0;
    transition: transform 0.3s ease-in-out;
    padding: 15px; /* Reduce padding for a more compact look */
    max-height: 40vh; /* Limit height to avoid blocking too much of the screen */
    overflow-y: auto;
  }

  .details-panel h2 {
    font-size: 1.4em; /* Reduce title size for better fit */
    margin-bottom: 10px;
  }

  .details-panel ul {
    font-size: 1em; /* Adjust text size for readability */
  }

  .close-button {
    font-size: 0.9em; /* Make the close button slightly smaller */
    padding: 10px;
  }
}
